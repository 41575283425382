export enum ERoutes {
  Auth = '/auth/callback',
  Login = '/login',
  Dashboard = '/dashboard',
  Operations = '/operations',
  Event = '/event/:id',
}

export enum ENavOptions {
  'Dashboard' = 'Dashboard',
  'Operations' = 'Operations',
}

export enum ESearchParams {
  Status = 'status',
  Search = 'search',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  OrderField = 'orderField',
  OrderDirection = 'orderDirection',
  AssigneeId = 'assigneeId',
  EventType = 'type',
}

export enum EEventStatuses {
  Inbox = 'inbox',
  UnderReview = 'under_review',
  Outdated = 'outdated',
  Completed = 'completed',
  OnHold = 'on_hold',
}

export enum EEventOrderFields {
  Date = 'date',
  PatientName = 'patientName',
  Type = 'type',
  Age = 'age',
  Description = 'description',
  TimeSpent = 'timeSpent',
}

export enum EOperationsOrderFields {
  TimeSpent = 'timeSpent',
  StaffName = 'staffName',
  EventCount = 'eventCount',
  CcmEnrollmentsCount = 'ccmEnrollmentsCount',
  CcmPatientsCount = 'ccmPatientsCount',
  CcmTimeSpent = 'ccmTimeSpent',
  TotalTimeSpent = 'totalTimeSpent',
}

export enum EOrderDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EDateFormat {
  FullYear = 'MM/DD/YYYY',
  ShortYear = 'MM/DD/YY',
  ShortYear12hm = 'MM.DD.YY hh:mma',
  FullYear12hm = 'MM/DD/YYYY - hh:mma',
  ShortMonth12h = 'D MMM ha',
  FullTime24 = 'HH:mm:ss',
  ShortTime12 = 'h:mm a',
  FullYearReverse = 'YYYY-MM-DD',
  FullMonthDay = 'MMMM Do',
  DayFullMonthFullYear = 'DD MMMM YYYY',
}

export enum EEventStatusTitles {
  inbox = 'Inbox',
  under_review = 'Under Review',
  completed = 'Completed',
  outdated = 'Outdated',
  on_hold = 'On Hold',
}

export enum EActionTypes {
  Move = 'move',
  Complete = 'complete',
  Outdated = 'outdated',
  Back = 'back',
  Release = 'release',
  Hold = 'hold',
}

export enum EEventTypes {
  IIE = 'Intake & Instruction Event',
  TIE = 'Transfer Intake Event',
  MSE = 'Missed Survey Event',
  MBE = 'Missed Baseline Event',
  RRE = 'Retrieval Request Event',
  RFE = 'Retrieval Follow-up Event',
  URE = 'Unexpected Return Event',
  UNS = 'Unenrolled No Survey',
  ENS = 'Enrolled No Survey',
  ECE = 'Education Call Event',
  PCR = 'Patient Call Request',
}

export enum EEventTypesShort {
  All = 'All',
  IIE = 'IIE',
  TIE = 'TIE',
  MSE = 'MSE',
  MBE = 'MBE',
  RRE = 'RRE',
  RFE = 'RFE',
  URE = 'URE',
  UNS = 'UNS',
  ENS = 'ENS',
  ECE = 'ECE',
  PCR = 'PCR',
}

export enum EReviewHistoryActionTypes {
  received = 'received',
  moved_back = 'moved_back',
  completed = 'completed',
  outdated = 'outdated',
  call = 'call',
  spent_time_record_is_added = 'spent_time_record_is_added',
  spent_time_record_is_edited = 'spent_time_record_is_edited',
  comment_is_left = 'comment_is_left',
  spent_time_cancelled = 'spent_time_cancelled',
  review = 'review',
  hold = 'hold',
  hold_was_updated = 'hold_was_updated',
  release = 'release',
  marked_as_unable_to_contact = 'marked_as_unable_to_contact',
  unmarked_as_unable_to_contact = 'unmarked_as_unable_to_contact',
  call_requested = 'call_requested',
}

export enum EReviewHistoryActionTitles {
  received = 'Incoming event item for review',
  moved_back = 'Moved back to Inbox',
  completed = 'Event is completed',
  outdated = 'Event is outdated',
  call = 'Call to the patient',
  spent_time_record_is_added = 'Time spent',
  spent_time_record_is_edited = 'Time edited',
  comment_is_left = 'A comment is left',
  spent_time_cancelled = 'Time deleted',
  review = 'Review',
  hold = 'Placed on hold',
  hold_was_updated = 'Hold was updated',
  release = 'Released from hold',
  marked_as_unable_to_contact = 'Marked as “Unable to contact”',
  unmarked_as_unable_to_contact = 'Unmarked as “Unable to Contact”',
  call_requested = 'Call Requested',
}

export enum EEventTaskStatuses {
  Completed = 'completed',
  NotCompleted = 'not_completed',
}

export enum EEventTaskStatusTitles {
  Completed = 'Completed',
  NotCompleted = 'Not Completed',
}

export enum EHoldReason {
  BusySignal = 'busy_signal',
  Disconnected = 'disconnected',
  LeftVoicemail = 'left_voicemail',
  PatientUnavailable = 'patient_unavailable',
  PendingAssistance = 'pending_assistance',
  SupervisorHelpRequired = 'supervisor_help_required',
  UnableToLeaveVoicemail = 'unable_to_leave_voicemail',
  VoicemailNotRequired = 'voicemail_not_required',
  WrongPhoneNumber = 'wrong_phone_number',
}

export enum EHoldReasonLabel {
  busy_signal = 'Busy Signal',
  disconnected = 'Disconnected',
  left_voicemail = 'Left Voicemail',
  patient_unavailable = 'Patient Unavailable',
  pending_assistance = 'Pending Assistance',
  supervisor_help_required = 'Supervisor Help Required',
  unable_to_leave_voicemail = 'Unable to Leave Voicemail',
  voicemail_not_required = 'Voicemail Not Required',
  wrong_phone_number = 'Wrong Phone Number',
}

export enum EOutdatedReasonLabel {
  TrainingIssue = 'Training Issue',
  SystemError = 'System Error',
  UnableToContact = 'Unable to Contact',
  Other = 'Other',
}
