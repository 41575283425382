import React from 'react';

interface IComponentProps {
  email: string;
}

export const TeamsCall: React.FC<IComponentProps> = ({ email }) => (
  <div className="phone">
    <p className="phone__number">
      <a href={`https://teams.microsoft.com/l/call/0/0?users=${email}`}>Call via Teams</a>
    </p>
  </div>
);
