import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button } from 'antd';
import parse from 'html-react-parser';
import { EReviewHistoryActionTypes, EReviewHistoryActionTitles, EDateFormat, EHoldReasonLabel } from 'common/const/enums.const';
import { convertToTimerFormat } from 'common/helpers/time.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as EditIcon } from 'app/assets/images/svg/edit-icon.svg';
import { IEventHistoryItem } from 'entities/ReviewHistory/ReviewHistory.models';
import { ReviewHistory } from 'entities/ReviewHistory/components/ReviewHistory';

interface IComponentProps {
  eventId: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const EventReviewHistoryComponent: React.FC<AllType> = (props) => {
  const {
    eventId,
    eventReviewHistoryCollection,
    getEventReviewHistoryCollection,
    updateEventReviewCommentHistory,
    setCommentModalParams,
  } = props;
  const { data: reviewHistoryData, loading } = eventReviewHistoryCollection;

  const onSendClick = (eventHistoryId: string, comment: string) => {
    updateEventReviewCommentHistory({ eventHistoryId, comment, onSuccess: () => getEventReviewHistoryCollection(eventId) }).then(
      () => {
        setCommentModalParams({ open: false, externalId: null, edit: false });
      }
    );
  };

  const onCancelClick = () => {
    setCommentModalParams({ open: false, externalId: null, edit: false });
  };

  const onEditClick = (item: IEventHistoryItem) => {
    setCommentModalParams({
      open: true,
      externalId: item.id,
      edit: true,
      defaultValue: item.comment,
      onSendClick,
      onCancelClick,
    });
  };

  const renderHistoryDescription = (item: IEventHistoryItem) => {
    switch (item.type) {
      case EReviewHistoryActionTypes.spent_time_record_is_added:
      case EReviewHistoryActionTypes.spent_time_record_is_edited:
      case EReviewHistoryActionTypes.spent_time_cancelled: {
        return (
          <div className="history-card__item_description">
            <div>
              {EReviewHistoryActionTitles[item.type]}: {convertToTimerFormat(item.time)}
            </div>

            <span className="history-card__item_comment">
              <span className="history-card__item_comment-title">Comment:</span>
              {item.comment ? (
                <span>{parse(item.comment)}</span>
              ) : (
                <span className="history-card__item_comment-red">No comment provided</span>
              )}
            </span>
            <Button className="btn-link history-card__item_btn-edit" icon={<EditIcon />} onClick={() => onEditClick(item)}>
              Edit
            </Button>
          </div>
        );
      }
      case EReviewHistoryActionTypes.moved_back: {
        return (
          <div className="history-card__item_description">
            <div>{EReviewHistoryActionTitles.moved_back}</div>
          </div>
        );
      }
      case EReviewHistoryActionTypes.comment_is_left: {
        return (
          <span className="history-card__item_comment">
            <span className="history-card__item_comment-title">Comment:</span>
            <span>{item.comment && parse(item.comment)}</span>
          </span>
        );
      }
      case EReviewHistoryActionTypes.call_requested:
        return (
          <span className="history-card__item_comment">
            <span className="history-card__item_comment-title">Call requested.</span>
            <span>{item.comment && parse(item.comment)}</span>
          </span>
        );
      case EReviewHistoryActionTypes.completed:
      case EReviewHistoryActionTypes.review:
      case EReviewHistoryActionTypes.release:
      case EReviewHistoryActionTypes.marked_as_unable_to_contact:
      case EReviewHistoryActionTypes.unmarked_as_unable_to_contact: {
        return <div className="history-card__item_description">{EReviewHistoryActionTitles[item.type]}</div>;
      }
      case EReviewHistoryActionTypes.outdated: {
        return (
          <div className="history-card__item_description">
            <div>Marked outdated</div>

            <span className="history-card__item_comment">
              <span className="history-card__item_comment-title">with the reason </span>
              {item.comment ? (
                <span>{`"${parse(item.comment)}"`}</span>
              ) : (
                <span className="history-card__item_comment-red">{'"No comment provided"'}</span>
              )}
            </span>
          </div>
        );
      }
      case EReviewHistoryActionTypes.hold:
      case EReviewHistoryActionTypes.hold_was_updated: {
        return (
          <div className="history-card__item_description">
            <div>{EReviewHistoryActionTitles[item.type]}</div>

            <div className="history-card__item_comment history-card__item_comment-title">
              {`until ${moment(item.holdUntil).format(EDateFormat.FullYear)}, with the reason “${
                EHoldReasonLabel[item.holdReason as keyof typeof EHoldReasonLabel]
              }”`}
            </div>

            <span className="history-card__item_comment">
              <span className="history-card__item_comment-title">Note:</span>
              {item.comment ? (
                <span>{parse(item.comment)}</span>
              ) : (
                <span className="history-card__item_comment-red">No comment provided</span>
              )}
            </span>
          </div>
        );
      }
      default: {
        return (
          <div className="history-card__item_description history-card__item_comment-title">
            {EReviewHistoryActionTitles[item.type]}
          </div>
        );
      }
    }
  };

  useEffect(() => {
    getEventReviewHistoryCollection(eventId);
  }, []);

  if (!reviewHistoryData) {
    return null;
  }

  return (
    <ReviewHistory loading={loading}>
      {reviewHistoryData?.data.map((item) => {
        return (
          <div key={item.id} className="history-card__item">
            <div className="history-card__item_header">
              <span>{moment(item.createdAt).format(EDateFormat.FullYear12hm)}</span>
              {item.type !== EReviewHistoryActionTypes.received && <span>by: {item.user?.name ? item.user.name : 'System'}</span>}
            </div>
            {renderHistoryDescription(item)}
          </div>
        );
      })}
    </ReviewHistory>
  );
};

const mapState = (state: RootState) => ({
  eventReviewHistoryCollection: state.eventReviewHistoryCollection,
});

const mapDispatch = (dispatch: RootDispatch) => ({
  getEventReviewHistoryCollection: dispatch.eventReviewHistoryCollection.getEventReviewHistoryCollection,
  updateEventReviewCommentHistory: dispatch.eventReviewHistoryCollection.updateEventReviewCommentHistory,
  setCommentModalParams: dispatch.modals.setCommentModalParams,
});

export const EventReviewHistory = connect(mapState, mapDispatch)(EventReviewHistoryComponent);
