import React from 'react';
import { EEventStatuses, EEventStatusTitles, EEventTypes, EEventTypesShort } from 'common/const/enums.const';
import { Retrieval } from 'entities/Retrieval/components/Retrieval';
import { IEventModelDto } from 'entities/Event/Event.models';
import { IPatientDto } from 'entities/Patient/Patient.models';
import { PatientDetails } from 'entities/Patient/components/PatientDetails';
import { EventDetails } from 'entities/Event/components/EventDetails';
import { CarePlan } from 'entities/CarePlan/components/CarePlan';
import { EventTasks } from 'entities/Tasks/components/EventTasks';

interface IComponentProps {
  eventModelData: IEventModelDto;
  patientModelData: IPatientDto;
}

export const EventBody: React.FC<IComponentProps> = (props) => {
  const { eventModelData, patientModelData } = props;
  const {
    patientName,
    status,
    type,
    lastFour,
    language,
    patientDetailLink,
    patientDetailErpLink,
    retrievalsLink,
    relatedCarePlans,
    contraindicationsLink,
    patientLocalTime,
    bestTimeToCall,
    outdatedReason,
  } = eventModelData;
  const { gender, dob, id: patientId } = patientModelData;

  return (
    <div className="event-body">
      <div className="event-body__header">
        <p className="title">{patientName}</p>
        <div className="event-body__header_container">
          <div className="event-body__header_item">
            <span className="event-body__header_item-title">Status:</span>
            <span>{EEventStatusTitles[status as keyof typeof EEventStatusTitles]}</span>
            {status === EEventStatuses.Outdated && <span>({outdatedReason})</span>}
          </div>
          <div className="event-body__header_item">
            <span className="event-body__header_item-title">Event type:</span>
            <span>{EEventTypes[type as keyof typeof EEventTypes]}</span>
          </div>
        </div>
      </div>

      <div className="patient-details">
        <PatientDetails
          details={{
            gender,
            dob,
            lastFour,
            language,
            patientLocalTime,
            bestTimeToCall,
            patientDetailLink,
            patientDetailErpLink,
            retrievalsLink,
          }}
        />
      </div>

      {[EEventTypesShort.RRE, EEventTypesShort.RFE, EEventTypesShort.URE].includes(type as EEventTypesShort) && (
        <Retrieval patientId={patientId} />
      )}

      {[
        EEventTypesShort.MBE,
        EEventTypesShort.MSE,
        EEventTypesShort.IIE,
        EEventTypesShort.TIE,
        EEventTypesShort.ECE,
        EEventTypesShort.PCR,
      ].includes(type as EEventTypesShort) && (
        <>
          <EventDetails relatedCarePlans={relatedCarePlans} contraindicationsLink={contraindicationsLink} />
          <CarePlan patientId={patientId} />
        </>
      )}

      <EventTasks eventStatus={status} />
    </div>
  );
};
